import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useActions, useReduxState } from "re-reduced";

import EventsList from "@components/scanner/eventsList";
import * as selectors from "@state/selectors";
import actions from "@state/actions";
import { pages } from "@util/constants";

const EventsPage = () => {
  const { loggedIn } = useReduxState({
    loggedIn: selectors.getLoggedIn,
  });
  const { updateRoute, getEvents } = useActions(actions);

  useEffect(() => {
    if (loggedIn) {
      updateRoute("events");
      getEvents();
    }
  }, []);

  if (!loggedIn) {
    if (typeof window !== "undefined") navigate(pages.login);
    return null;
  }

  return <EventsList />;
};

export default EventsPage;
