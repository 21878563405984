import moment from "moment";
import { useActions, useReduxState } from "re-reduced";
import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { RequestStatus } from "@state/types";
import { Loading, TextInput } from "@sub";
import { assets, colors, pages } from "@util/constants";
import { Container, P } from "@util/standard";
import { navigate } from "gatsby-link";
import { ListEvent } from "@util/api";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 90%;
  margin: 30px auto;
`;

const RelativeContainer = styled(Container)`
  position: relative;
`;

const ResultContainer = styled(RelativeContainer)`
  padding: 15px 10px;
  background-color: ${colors.grey};
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Icon = styled.img`
  position: absolute;
  right: 20px;
  top: 18px;
`;

const EventsList = () => {
  const { fetchEvent, fetchSearchTicketsByEventApp } = useActions(actions);

  const { events, requestStatus } = useReduxState({
    events: selectors.getEventList,
    requestStatus: selectors.getRequestStatus,
  });
  const [filteredEvents, setFilteredEvents] = useState<ListEvent[] | undefined>(
    events
  );

  // Beacuse Fetching events is async so we need to add into
  // the filtered state to show in the UI on first load.
  useEffect(() => {
    if (events) {
      setFilteredEvents(events);
    }
  }, [events]);

  const handleSearch = (userInput: string) => {
    if (userInput === "") {
      setFilteredEvents(events);
    } else if (events) {
      const filtered = events.filter((event) => {
        return event.title.toLowerCase().includes(userInput.toLowerCase());
      });
      setFilteredEvents(filtered);
    }
  };

  const handleEventClick = (eventId: string) => {
    fetchEvent(eventId);
    fetchSearchTicketsByEventApp({ eventId })

    // Take us to the event details page immediately
    // as there will be a loading state shown there.
    navigate(pages.home);
  };

  return (
    <Wrapper>
      <RelativeContainer>
        <TextInput
          placeholderText="Search event"
          onChange={(event: FormEvent<HTMLInputElement>) => handleSearch(event.currentTarget.value)}
          margin="0 0 20px 0"
        />
        <Icon src={assets.search} />
      </RelativeContainer>

      {requestStatus === RequestStatus.Pending && (
        <Container width="100%" justifyContent="center" alignItems="center" margin="12px 0 0 0">
          <Loading />
        </Container>
      )}

      {requestStatus !== RequestStatus.Pending && (
        <Container flexDirection="column">
          {filteredEvents?.map((event) => {
            return (
              <ResultContainer
                key={event.id}
                onClick={() => handleEventClick(event.id)}
              >
                <div>
                  <P margin="0 0 2px 0">{event.title}</P>
                  <P fontSize={16} color="lightGrey" noMargin>{moment(event.startDate).format("Do MMM YY")}</P></div>
                <img src={assets.rightArrow} />
              </ResultContainer>
            );
          })}
        </Container>
      )}
    </Wrapper>
  );
};

export default EventsList;
